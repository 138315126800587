import { FC } from "react";
import { Typography, Grid } from "@mui/material";

import { Root } from "../../../../components/common/styled";
import { StyledIcon, StyledImage, StyledLink, StyledText } from "./styled";

export const OurTeam: FC = () => {
  const team = [
    // {
    //   avatar: "/files/team/oleg.png",
    //   name: "Oleg G.",
    //   position: "CEO",
    //   link: "https://www.linkedin.com/in/trejgun/",
    //   linkIconAlt: "LinkedIn",
    //   linkIcon: "/files/team/linkedin.svg",
    // },
    {
      avatar: "/files/team/yury.png",
      name: "Yury T.",
      position: "CEO / CFO",
      link: "https://www.linkedin.com/in/yury-toporov-534423b6/",
      linkIconAlt: "LinkedIn",
      linkIcon: "/files/team/linkedin.svg",
    },
    {
      avatar: "/files/team/pasha.jpeg",
      name: "Pavel Nikalaichyk",
      position: "Fullstack developer",
      link: "https://www.linkedin.com/in/pavel-nikalaichyk/",
      linkIconAlt: "LinkedIn",
      linkIcon: "/files/team/linkedin.svg",
    },
    {
      avatar: "/files/team/yulia.jpg",
      name: "Yulia Zhigulova",
      position: "Artist/Designer",
      link: "https://www.behance.net/YuliaZhigulova",
      linkIconAlt: "Behance",
      linkIcon: "/files/team/behance.jpg",
    },
    {
      avatar: "/files/team/igor.jpeg",
      name: "Igor Berlenko",
      position: "Solidity Developer",
      link: "#",
      linkIconAlt: "LinkedIn",
      linkIcon: "/files/team/linkedin.svg",
    },
    {
      avatar: "/files/team/daniel.png",
      name: "Daniel Ocean",
      position: "Business Development",
      link: "https://www.linkedin.com/in/daniel-ocean-crypto/",
      linkIconAlt: "LinkedIn",
      linkIcon: "/files/team/linkedin.svg",
    },
    {
      avatar: "/files/team/anna.png",
      name: "Anna Korkh",
      position: "QA (Automation tester)",
      link: "https://www.linkedin.com/in/anna-korkh-31984a33a/",
      linkIconAlt: "LinkedIn",
      linkIcon: "/files/team/linkedin.svg",
    },
  ];

  return (
    <Root>
      <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
        WHY GEMUNION?
      </Typography>
      <Typography variant="h4" sx={{ textAlign: "center", my: 1 }}>
        Team of Senior Specialists with tech background
      </Typography>
      <Typography variant="h5" sx={{ textAlign: "center", mb: 7 }}>
        5+ years of experience | 100+ successfully completed projects
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {team.map(hero => (
          <Grid key={hero.link} item xs={12} sm={6} md={4} sx={{ mb: 5 }}>
            <StyledImage component="img" src={hero.avatar} alt={hero.name} />
            <StyledLink href={hero.link}>
              <StyledIcon component="img" src={hero.linkIcon} alt={hero.linkIconAlt} />
            </StyledLink>
            <StyledText>
              {hero.name}
              <br />
              {hero.position}
            </StyledText>
          </Grid>
        ))}
      </Grid>
    </Root>
  );
};
