import type { RouteObject } from "react-router-dom";

import { Survey } from "./survey";
import { pricingRoutes } from "./pricing/routes";
import { Payment } from "./payment";
import { PressKit } from "./press-kit";

export const portalRoutes: Array<RouteObject> = [
  ...pricingRoutes,
  {
    path: "/survey",
    element: <Survey />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },
  // {
  //   path: "/press-kit",
  //   element: <PressKit />,
  // },
];
