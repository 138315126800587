import { FC } from "react";
import { Typography, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { MenuBook } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

import { Root } from "../../../components/common/styled";

export const ArticlesList: FC = () => {
  return (
    <Root>
      <Typography variant="h4" sx={{ mt: 4 }}>
        Articles
      </Typography>
      <List>
        <ListItemButton component={RouterLink} to="/pages/discover-how-cascadify-can-help-your-startup">
          <ListItemIcon>
            <MenuBook />
          </ListItemIcon>
          <ListItemText>Discover how Cascadify can help your startup</ListItemText>
        </ListItemButton>
        <ListItemButton component={RouterLink} to="/pages/how-to-build-a-diablo-like-game">
          <ListItemIcon>
            <MenuBook />
          </ListItemIcon>
          <ListItemText>How to build a Diablo-like game</ListItemText>
        </ListItemButton>
      </List>
    </Root>
  );
};
