import { useIntl } from "react-intl";

export interface IMenuItem {
  id: number;
  link?: string;
  title: string;
  children?: IMenuItem[];
  external?: boolean;
}

export const useMenuItems = (): IMenuItem[] => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { formatMessage } = useIntl();

  const home = formatMessage({ id: "components.header.menu.home" });
  const docs = formatMessage({ id: "components.header.menu.docs" });
  const pricing = formatMessage({ id: "components.header.menu.pricing" });
  const media = formatMessage({ id: "components.header.menu.media" });
  const events = formatMessage({ id: "components.header.menu.events" });
  const articles = formatMessage({ id: "components.header.menu.articles" });
  const company = formatMessage({ id: "components.header.menu.company" });
  const ourTeam = formatMessage({ id: "components.header.menu.ourTeam" });
  const technicalStack = formatMessage({ id: "components.header.menu.technicalStack" });
  const partnership = formatMessage({ id: "components.header.menu.partnership" });
  // const pressKit = formatMessage({ id: "components.header.menu.pressKit" });

  return [
    {
      id: 1,
      link: "/",
      title: home,
    },
    {
      id: 2,
      link: "https://dzambalaorg.github.io",
      title: docs,
      external: true,
    },
    {
      id: 3,
      link: "/pricing",
      title: pricing,
    },
    {
      id: 4,
      title: media,
      children: [
        {
          id: 41,
          link: "/events",
          title: events,
        },
        {
          id: 42,
          link: "/articles",
          title: articles,
        },
      ],
    },
    {
      id: 5,
      title: company,
      children: [
        {
          id: 51,
          link: "/our-team",
          title: ourTeam,
        },
        {
          id: 52,
          link: "/technical-stack",
          title: technicalStack,
        },
        {
          id: 53,
          link: "/contact/partnership",
          title: partnership,
        },
        // {
        //   id: 54,
        //   link: "/press-kit",
        //   title: pressKit,
        // },
      ],
    },
  ];
};
