import { FC } from "react";
import { Grid, Hidden, useScrollTrigger } from "@mui/material";
import { MetamaskLoginButton } from "@dzambalaorg/login-button-metamask";
import { ParticleLoginButton } from "@dzambalaorg/login-button-particle";
import { WalletConnectLoginButton } from "@dzambalaorg/login-button-wallet-connect";
import { FirebaseLogin } from "@dzambalaorg/firebase-login";
import { NetworkButton, WalletButton } from "@dzambalaorg/provider-wallet";

import { Menu } from "./menu";
import { Sections } from "./sections";
import { StyledAppBar, StyledLink, StyledLogo, StyledToolbar } from "./styled";
import { MobileMenu } from "./mobile-menu";
import { Link } from "react-router-dom";

export const Header: FC = () => {
  const scrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <StyledAppBar position="fixed" elevation={scrolled ? 1 : 0}>
      <StyledToolbar scrolled={scrolled}>
        <Grid container>
          <Grid item xs={12} md={5} justifyContent="space-between" sx={{ display: "flex", alignItems: "center" }}>
            <Hidden mdDown>
              <Menu />
            </Hidden>
            <Hidden mdUp>
              <StyledLink component={Link} to="/">
                <StyledLogo scrolled={scrolled} component={"img"} src={"/files/logo_text.png"} />
              </StyledLink>

              <MobileMenu />
            </Hidden>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={3.5} justifyContent="flex-start" sx={{ display: "flex", alignItems: "center" }}>
              <StyledLink component={Link} to="/">
                <StyledLogo scrolled={scrolled} component={"img"} src={"/files/logo_text.png"} />
              </StyledLink>
            </Grid>
            <Grid item xs={12} md={3.5} justifyContent="flex-end" sx={{ display: "flex", alignItems: "center" }}>
              <NetworkButton />
              <WalletButton>
                <FirebaseLogin
                  withEmail={false}
                  buttons={[MetamaskLoginButton, WalletConnectLoginButton, ParticleLoginButton]}
                />
              </WalletButton>
              <Sections />
            </Grid>
          </Hidden>
        </Grid>
      </StyledToolbar>
    </StyledAppBar>
  );
};
