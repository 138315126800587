import { FC } from "react";
import { Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Typewriter from "typewriter-effect";

import { StyledBold } from "../../../components/common/styled";
import {
  Root,
  StyledButton,
  StyledButtonWrapper,
  StyledHeroDescription,
  StyledHeroTitle,
  // StyledImage,
  StyledContent,
  StyledDownloadButton,
} from "./styled";

export const Hero: FC = () => {
  const { formatMessage } = useIntl();

  const defaultIndexes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  function randomize(): string[] {
    const shuffledArray = defaultIndexes.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray.map(index => formatMessage({ id: `pages.landing.hero.title.animated${index}` }));
  }

  return (
    <Root>
      <StyledContent>
        <StyledHeroTitle>
          <FormattedMessage id="pages.landing.hero.title.first" />
          <StyledBold>
            <Typewriter
              options={{
                loop: true,
                autoStart: true,
                strings: [
                  ...randomize(),
                  ...randomize(),
                  ...randomize(),
                  ...randomize(),
                  ...randomize(),
                  ...randomize(),
                ],
              }}
            />
          </StyledBold>
          <FormattedMessage id="pages.landing.hero.title.last" />
        </StyledHeroTitle>
        <StyledHeroDescription>
          <FormattedMessage id="pages.landing.hero.description" />
        </StyledHeroDescription>
        <StyledButtonWrapper>
          <StyledDownloadButton
            id="pitchdeck-button"
            component="a"
            // href="https://docsend.com/view/psaci7zcgh88h2pt"
            href="https://drive.google.com/file/d/1tc-jiFuhzmiAZqoQPZop1-iXWqsdUF_N/view?usp=drive_link"
            target="_blank"
            size="large"
          >
            <Box component="img" src="/files/landing/new/clouds.png" sx={{ width: 24, mr: 1.7 }} />
            <FormattedMessage id="pages.landing.hero.downloads.pitchdeck" />
          </StyledDownloadButton>
          <StyledButton
            id="showcases-button"
            component="a"
            // href="https://calendly.com/trejgun/30min"
            // href="https://t.me/ubudragon"
            href="https://wa.link/cascadifyio"
            target="_blank"
            size="large"
          >
            <FormattedMessage id="pages.landing.hero.downloads.bookCall" />
          </StyledButton>
        </StyledButtonWrapper>
      </StyledContent>
    </Root>
  );
};
