import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import DiscordLogo from "../../../../static/files/landing/icons/socials/d.svg";
import { StyledLogo, StyledLink } from "./styled";

export const Discord: FC = () => {
  return (
    <StyledLink component={RouterLink} to="https://discord.com/invite/FUEyPU82YC" target="_blank">
      <StyledLogo component={DiscordLogo} alt="Cascadify Discord" viewBox="0 0 30 30" />
    </StyledLink>
  );
};
