import { FC } from "react";

import NpmLogo from "../../../../../static/files/landing/icons/socials/p.svg";
import GithubLogo from "../../../../../static/files/landing/icons/socials/с.svg";
// import LinkedInLogo from "../../../../../static/files/landing/icons/socials/in.svg";
// import FacebookLogo from "../../../../../static/files/landing/icons/socials/f.svg";
// import TelegramLogo from "../../../../../static/files/landing/icons/socials/t.svg";
import YoutubeLogo from "../../../../../static/files/landing/icons/socials/y.svg";
// import DiscordLogo from "../../../../../static/files/landing/icons/socials/d.svg";
import MediumLogo from "../../../../../static/files/landing/icons/socials/m.svg";
import { StyledLink, StyledLogo, StyledWrapper } from "./styled";

export const SocialLinks: FC = () => {
  return (
    <StyledWrapper>
      {/*<StyledLink href="https://www.linkedin.com/company/gemunion" target="_blank">*/}
      {/*  <StyledLogo component={LinkedInLogo} viewBox="0 0 30 30" />*/}
      {/*</StyledLink>*/}
      <StyledLink href="https://github.com/dzambalaorg" target="_blank">
        <StyledLogo component={GithubLogo} viewBox="0 0 30 30" />
      </StyledLink>
      {/*<StyledLink href="https://www.facebook.com/gemunion" target="_blank">*/}
      {/*  <StyledLogo component={FacebookLogo} viewBox="0 0 30 30" />*/}
      {/*</StyledLink>*/}
      {/*<StyledLink href="https://t.me/gemunion_studio" target="_blank">*/}
      {/*  <StyledLogo component={TelegramLogo} viewBox="0 0 30 30" />*/}
      {/*</StyledLink>*/}
      <StyledLink href="https://www.npmjs.com/org/gemunion" target="_blank">
        <StyledLogo component={NpmLogo} viewBox="0 0 30 30" />
      </StyledLink>
      <StyledLink href="https://www.youtube.com/@gemunion_studio" target="_blank">
        <StyledLogo component={YoutubeLogo} viewBox="0 0 30 30" />
      </StyledLink>
      {/*<StyledLink href="https://discord.com/invite/FUEyPU82YC" target="_blank">*/}
      {/*  <StyledLogo component={DiscordLogo} viewBox="0 0 30 30" />*/}
      {/*</StyledLink>*/}
      <StyledLink href="https://medium.com/@gemunion" target="_blank">
        <StyledLogo component={MediumLogo} viewBox="0 0 30 30" />
      </StyledLink>
    </StyledWrapper>
  );
};
